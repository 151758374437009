.form-loading {
  display: none;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 30px auto 0;
  background: url('#{$img-path}base/loading.gif') no-repeat;
  width: 100px;
  height: 99px;
  background-size: contain;
}

.error-message, .success-message {
  display: none;
  font-size: 1.125rem;
  text-align: center;
}

.has-error .help-block {
  color: #b11813;
  font-size: 0.9rem;
}

.form-block {
  input[type="text"], input[type="email"], input[type="password"], textarea, select {
    border-radius: 0;
    font-size: 1.125rem;
    font-family: $main-font;
    border: 1px solid #d4dce2;
    padding: .45rem .75rem;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #818181;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #818181;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #818181;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #818181;
    }
  }

  select {
    padding: 0 .75rem;
    color: #818181;
    min-height: 2.7rem;
  }

  textarea {
    resize: none;
  }

  .hasDatepicker {
    line-height: inherit;
    width: 100%;
  }

  .calendar-icon {
    position: absolute;
    right: 14px;
    top: 10px;

    &:after {
      content: "";
      display: block;
      background: url('#{$img-path}icons/ic_calendar.png');
      width: 20px;
      height: 20px;
      position: absolute;
      right: 12px;
      top: 1px;
    }

    @media (max-width: 768px) {
      top: 7px;
    }
  }

  .has-success select {
    color: #495057;
  }
}

.sidebar-form {
  padding: 30px 21px 15px 21px;
  min-height: 220px;
}

.order-form {
  min-height: 300px;

  .form-group {
    margin-bottom: 0.9rem;
  }

  select {
    color: #495057;
  }

  .dropfile {
    padding-left: 5rem;

    &.dropped {
      padding-left: 0.75rem;
    }

    @media (max-width: 768px) {
      padding-left: 10rem;
    }

    @media (max-width: 768px) {
      padding-left: 10rem;
    }
    @media (max-width: 576px) {
      padding-left: 1rem;
    }
  }

  @media (max-width: 1200px) {
    font-size: 1rem;

    .checkbox {
      label {
        display: block;
      }
    }
  }
}

.order-form__group {
  padding: 25px 25px 5px 25px;
}

.order-form__spacer {
  background-color: #e4ebf2;
  font-weight: bold;
  padding: 15px 25px;

  label {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

.order-form__spacer_last {
  padding: 20px 25px 0 25px;
}

.dropfile {
  background-color: #f8fafc;
  min-height: 102px;
  cursor: pointer;
  border: 1px solid #d4dce2;
  padding: .6rem .75rem;

  &.dragover {
    background-color: rgba(123, 179, 239, 0.44);

    .bottom-border_dashed {
      border-bottom-style: solid;
    }
  }

  &.dropped {
    .dropfile__text {
      display: none;
    }
    .dropfile__file, .dropfile__delete {
      display: block;
    }
  }
}

.dropfile__text_attach {
  font-size: 1.125rem;
  color: $blue-text;
  padding-top: 10px;

  &:before {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_clip.png');
    width: 12px;
    height: 26px;
    vertical-align: text-top;
    margin: 0 8px 0 0;
  }
}

.dropfile__text_attach_letter {
  &:before {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_text.png');
    width: 24px;
    height: 28px;
    vertical-align: text-top;
    margin: 0 8px 0 0;
  }

  & + .dropfile__text_size {
    padding-left: 33px;
  }
}

.dropfile__text_size {
  color: #818181;
  padding-left: 21px;
  padding-top: 2px;
  font-size: 1rem;
}

.dropfile__file {
  display: none;
  color: #818181;
  word-break: break-all;

  &:before {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_clip.png');
    width: 12px;
    height: 26px;
    vertical-align: text-top;
    margin: 0 8px 0 0;
  }
}

.dropfile__delete {
  display: none;
  font-size: 1.125rem;
  color: $blue-text;
  margin-top: 10px;
}

.dropfile__errors {
  span {
    display: none;
  }
}

.add-lang {
  cursor: pointer;
  margin-right: 58px;
}