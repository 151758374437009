.page {
  color: $page-text;
  p {
    line-height: 1.5rem;
    margin-bottom: 1.55rem;
  }

  .bigger-text {
    margin-bottom: 3rem;
  }
}

.page_small {
  color: #404040;
  p {
    line-height: 1.45rem;
    margin-bottom: 1.2rem;
  }
}

.link {
  color: $link;
}

.link_simple {
  color: #404040;
  text-decoration: none!important;
}

.page-header__img {
  width: 100%;
  height: 158px;
  background-position: center;
  background-size: cover;

  @media (max-width: 576px) {
    height: 80px;
  }
  @media (max-width: 992px) {
    height: 100px;
  }
}

.page-header__img_flags {
  background-image: url('#{$img-path}index/pic_header_flags.jpg');
}

.page-header__img_customers {
  background-image: url('#{$img-path}index/pic_header_customers.jpg');
}

.page-header__img_online {
  background-image: url('#{$img-path}index/pic_header_online_services.jpg');
}

.page-header__img_langs {
  background-image: url('#{$img-path}index/pic_header_languages.jpg');
}

.page__header {
  margin-top: 4rem;
  margin-bottom: 2.15rem;
  padding-bottom: 0.8rem;
  color: $dark-header-text;
  font-size: 1.875rem;
  line-height: 2.3rem;
  border-bottom: 7px solid $header-border;

  @media (max-width: 576px) {
    margin-top: 2rem;
  }
}

.page__header_no-border {
  border-bottom: 0;
  padding-bottom: 0.4rem;
}

.page-header__inside {
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 1.25rem;
  margin-bottom: 1.55rem;
}

.pagination {
  li {
    background-color: #f1f6fa;
    border-radius: 40px;
    text-align: center;
    width: 30px;
    height: 30px;
    margin-left: 5px;

    a {
      font-size: 1.125rem;
      color: #7bb3ef;
      font-weight: bold;
      line-height: 30px;
      display: block;
      text-decoration: none!important;
    }

    &.disabled {
      display: none;
    }

    &.active {
      background-color: #7bb3ef;
      a {
        color: $light-text;
      }
    }

    &.next, &.prev {
      background-color: transparent;
    }
  }
}

.about__certificate {
  display: block;
  margin-bottom: 1rem;
}

.about__sidebar {
  color: $dark-header-text;
  text-align: center;
  border-bottom: 1px solid $gray-border;
  padding-bottom: 1.9rem;
  padding-top: 1.5rem;

  p {
    text-align: left;
    line-height: 1.3rem;
    margin-bottom: 1.9rem;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &:first-child {
    padding-top: 10rem;

    @media (max-width: 768px) {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid $gray-border;
    }
  }
}

.sidebar-header {
  font-size: 1.3125rem;
  color: $dark-header-text;
  margin-bottom: 1.3rem;
  margin-top: 4rem;
  line-height: 1.6rem;
}

.sidebar-header_inside {
  margin-top: 3rem;
}

.feedback__block {
  margin-top: 1.9rem;
}

.feedback__block_right {
  border-bottom: 1px solid $gray-border;
  padding-bottom: 1.9rem;
}

.news__img {
  margin-right: 30px;
  margin-top: 5px;
  margin-bottom: 2rem;
  min-width: 115px;

  img {
    max-width: 115px;

    @media (max-width: 768px) {
      max-width: 80px;
    }
  }

  @media (max-width: 768px) {
    min-width: 80px;
    margin-right: 15px;
  }
}

.news__img_index {
  margin-right: 15px;
}

.news__text {
  line-height: 1.3rem;
  margin-bottom: 2rem;
}

.news__title {
  line-height: 1.4rem;
  font-size: 1.125rem;
  color: $blue-text;
  margin-bottom: 0.8rem;
  display: block;
}

.news__date {
  margin-top: -0.5rem;
}

.one-news__date {
  margin-top: -20px;
  margin-bottom: 1.5rem!important;
}

.contacts {
  p {
    line-height: 1.7rem;
  }
}

.contacts__image {
  width: 263px;
  height: 162px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
  right: 24px;
  top: 8px;

  @media (max-width: 992px) {
    max-width: 125px;
    max-height: 80px;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.contacts__image_moscow {
  background-image: url('#{$img-path}pages/contacts/photo_map_moscow.jpg');
}

.contacts__image_pyatigorsk {
  background-image: url('#{$img-path}pages/contacts/photo_map_pyatigorsk.jpg');
}

.vacancy {
  @media (max-width: 576px) {
    word-wrap: break-word;
    p, div, span {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 768px) {
    .collapse-arrow-down_triangle {
      display: none;
    }
  }
}

.vacancy__header {
  font-weight: bold;
  padding-bottom: 10px;
}

.vacancy__border {
  border-bottom: 1px solid $gray-border;
}

.vacancy__collapse_link {
  text-decoration: none!important;
  color: $page-text;
  padding: 10px 0;

  &:hover {
    color: $page-text;
  }

  &[aria-expanded="true"]{
    .vacancy__position {
      color: $page-text;
      font-weight: bold;
    }

    .collapse-arrow-down_triangle {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
}

.collapse-arrow-down_triangle {
  width: 15px;
  height: 14px;
  color: $link;
  display: block;
  position: absolute;
  top: 20%;
  right: 20px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.vacancy__position {
  color: #00468f;
  font-size: 1.125rem;
}

.vacancy__body {
  padding-top: 0.7rem;
}

.vacancy__icon {
  margin-right: 10px;
}

.section-link {
  display: block;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: $blue-text!important;
  text-decoration: none!important;
  margin-top: 4rem;
  font-weight: bold;
  text-align: center;
  padding-bottom: 25px;
  box-shadow: 0px 5px 15px 0px rgba(50, 50, 50, 0.2);
  transition: all 0.2s ease-in-out;

  img {
    margin-bottom: 18px;
  }

  &:hover {
    box-shadow: 0px 5px 20px 0px rgba(50, 50, 50, 0.3);
  }
}

.turbo__bg {
  background-image: url('#{$img-path}pages/online-services/bg_turbo_how_work.jpg');
  background-size: cover;
  min-height: 862px;
  width: 100%;
  padding-top: 40px;
  position: relative;
  padding-bottom: 30px;

  @media (max-width: 768px) {
    padding-top: 20px;
    min-height: 450px;
  }
}

.turbo__phones {
  background-image: url('#{$img-path}pages/online-services/turbo_telephones.png');
  background-size: contain;
  position: absolute;
  height: 649px;
  width: 756px;
  margin: 30px auto 0 auto;
  left: 10px;
  right: 0;

  @media (max-width: 1200px) {
    height: 540px;
    width: 630px;
  }
  @media (max-width: 992px) {
    margin-top: 0;
    position: relative;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.turbo__block {
  position: relative;

  @media (max-width: 1200px) {
    font-size: 1rem;
  }
  @media (max-width: 992px) {
    text-align: left!important;
    margin-top: 25px!important;
    margin-left: 45px;
  }
}

.turbo__num {
  display: inline-block;
  border-radius: 60px;
  width: 40px;
  height: 40px;
  color: $light-text;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  position: absolute;

  @media (max-width: 992px) {
    top: -5px!important;
    left: 0!important;
    margin-left: -45px!important;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.turbo__num_blue {
  background-color: #00579e;
}

.turbo__num_orange {
  background-color: #f05a22;
}

.turbo__block__title_orange {
  color: #f35a0b;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
}

.turbo__block_1 {
  margin-top: 33px;

  .turbo__num {
    top: 30%;
    margin-left: 15px;
  }
}

.turbo__block_2 {
  margin-top: 85px;

  .turbo__num {
    top: 10%;
    margin-left: 15px;
  }
}

.turbo__block_3 {
  margin-top: 550px;

  .turbo__num {
    top: 10%;
    margin-left: 15px;
  }

  @media (max-width: 1200px) {
    margin-top: 455px;
  }
}

.turbo__block_4 {
  margin-top: 150px;

  .turbo__num {
    top: 10%;
    margin-left: -55px;
  }

  @media (max-width: 1200px) {
    margin-top: 120px;
  }
}

.turbo__block_5 {
  margin-top: 85px;

  .turbo__num {
    top: 10%;
    margin-left: -55px;
  }

  @media (max-width: 1200px) {
    margin-top: 70px;
  }
}

.turbo__block_6 {
  margin-top: 100px;

  .turbo__num {
    top: 5%;
    margin-left: -55px;
  }

  @media (max-width: 1200px) {
    margin-top: 70px;
  }
}

.link__blue-line {
  background-color: #4a7cc5;
  color: $light-text!important;
  text-decoration: underline;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 18px 0;
  display: block;
  text-align: center;
}

.icons-with-text {
  margin-bottom: 40px;

  img {
    margin: 0 0 30px 0;
  }
  .bigger-text {
    line-height: 1.6rem!important;
    min-height: 50px;
  }
  p {
    line-height: 1.4rem;
  }
}

.speakus__gradient {
  background: #daeeff; /* Old browsers */
  background: -moz-linear-gradient(left, #daeeff 0%, #f1f4f6 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #daeeff 0%,#f1f4f6 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #daeeff 0%,#f1f4f6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#daeeff', endColorstr='#f1f4f6',GradientType=1 ); /* IE6-9 */

  padding: 35px 30px 20px 30px;
}

.speakus__how-works {
  background-color: #eaf1f8;
  padding: 40px 0;

  img {
    margin: 20px 0 30px 0;
  }

  p {
    line-height: 1.6rem;
  }
}

.translations {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.translations__col {
  width: 33%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.translations__row {
  display: flex;
  align-items: center;
  min-height: 65px;
  margin-bottom: 5px;
  background-color: #dcefff;
  color: $blue-text;
  text-decoration: none!important;
  transition: 0.2s all ease-in-out;

  &:nth-child(2n+1) {
    background-color: #eef7fe;
  }

  &:hover {
    color: $light-text;
    background-color: #0272bf;

    .translations__price {
      display: block;
    }

    .translations__lang {
      display: none;
    }
  }
}

.one-translation__price {
  background-color: #dcefff;
  color: $blue-text;
  min-height: 65px;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  padding-left: 1.25rem;
  margin-bottom: 2rem;

  div {
    width: 50%;
  }
}

.translations__lang {
  padding-left: 1.25rem;
  font-size: 1.125rem;
}

.translations__price {
  padding-left: 1.25rem;
  display: none;

  @media (max-width: 1200px) {
    padding-left: 0.8rem;
  }
}

.translations__after-header {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.translations__time {
  padding-left: 35px;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 24px;
    height: 25px;
    left: 0;
  }
}

.translations__time_usual {
  &:before {
    background: url('#{$img-path}icons/blue_dot.png');
  }
}

.translations__time_fast {
  &:before {
    background: url('#{$img-path}icons/orange_dot.png');
  }
}

.dynamic-page__menu {
  .page__header {
    margin-bottom: 1.5rem;
  }
  .link_simple {
    margin-bottom: 1rem;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 576px) {
      margin-bottom: 1rem!important;
    }

    &.active {
      color: $page-text;
      font-weight: bold;
    }
  }

  &:nth-child(2) {
    .page__header {
      border-color: #5faeff;
    }
  }

  &.dynamic-page__menu_padding-bottom {
    padding-bottom: 2rem;
  }
}

.breadcrumb {
  background: transparent;
  font-size: 0.875rem;
  margin-top: 2rem;
  margin-bottom: -2.5rem;
  padding: 0;

  a {
    color: #3b3b3b!important;
    text-decoration: none!important;
  }

  @media (max-width: 576px) {
    margin-top: 1rem;
    margin-bottom: -0.5rem;
  }
}

.page__price {
  background-color: $price-bg;
  font-weight: bold;
  font-size: 1.3125rem;
  min-height: 70px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin: 2.5rem 0 1.2rem 0;

  &.price-icon:before {
    @media (max-width: 768px) {
      width: 75px;
      margin-right: 1rem;
    }
    @media (max-width: 576px) {
      width: 75px;
      background-size: contain;
      background-position: center;
    }
  }

  @media (max-width: 576px) {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }
}

.how-to-order {
  background: url('#{$img-path}pages/dynamic/bg_kak_zakazat_perevod.jpg') no-repeat;
  background-size: cover;
  min-height: 566px;
  padding-bottom: 30px;
}

.how-to-order__header {
  font-size: 2.5rem;
  color: $dark-header-text;
  text-align: center;
  padding-top: 3.2rem;
  margin-bottom: 2.5rem;
}

.how-to-order__list {
  padding: 0 0 0 20px;
  flex-direction: column;
  margin-top: 25px;
}

.how-to-order__list_item {
  position: relative;
}

.how-to-order__list_link {
  font-size: 1.125rem;
  font-weight: bold;
  display: block;
  padding-left: 45px;
  color: $page-text!important;
  text-decoration: none!important;

  &.active {
    .how-to-order__list_num {
      background-color: #038dee;
    }
    &:after {
      border-color: #038dee;
    }
  }

  &:after {
    content: "";
    display: block;
    margin-right: -30px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #b0c3d4;
    transition: all 0.2s ease-in-out;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
}

.how-to-order__list_num {
  transition: all 0.2s ease-in-out;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50px;
  position: absolute;
  left: 0;
  text-align: center;
  background-color: #556877;
  color: $light-text;
  padding-top: 1px;

  @media (max-width: 992px) {
    padding-top: 3px;
  }

  @media (max-width: 768px) {
    padding-top: 4px;
  }
}

.how-to-order__content {
  padding: 25px 28px 10px 28px;
  background-color: #e4effb;
  min-height: 325px;
  box-shadow: 0 5px 15px 0 rgba(50,50,50,.2);

  p {
    line-height: 1.4rem;
  }
}

.page__price-table {
  border-top: 1px solid $dark-text;
  border-bottom: 1px solid $dark-text;
  display: flex;
  padding-top: 0.5rem;

  p {
    margin-bottom: 0.5rem;
  }

  & + .page__price-table {
    border-top: 0;
  }

  &:last-child {
    margin-bottom: 2rem;
  }
}

.page__price-table__header {
  width: 50%;
}

.page__price-table__text {
  width: 50%;
}

.service__content {
  a {
    @extend .link;
  }
  h2 {
    @extend .page-header__inside;
  }
}