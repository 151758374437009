// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
// Sliding mobile nav from left to right
@media (max-width: 992px) {
  .navbar-collapse {
    position: absolute;
    left: -100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 100%;

    -webkit-transition: left 0.3s ease-in-out;
    -o-transition: left 0.3s ease-in-out;
    -moz-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
  }
  .navbar-collapse.collapsing {
    height: auto;
  }
  .navbar-collapse.show {
    left: 0;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    padding-right: 15px;
  }
}


.geo-icon {
  &:before {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_point.png') no-repeat;
    width: 15px;
    height: 21px;
    vertical-align: bottom;
    margin: 2px 5px 0 0;
  }
}

.login-icon {
  &:before {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_login.png') no-repeat;
    width: 23px;
    height: 23px;
    vertical-align: text-bottom;
    margin: 0 8px 0 0;
  }
}

.read-icon {
  &:after {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_speakus_read.png') no-repeat;
    width: 23px;
    height: 23px;
    vertical-align: text-bottom;
    margin: 0 0 0 10px;
  }
}

.doc-icon {
  &:before {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_doc.png') no-repeat;
    width: 47px;
    height: 53px;
    vertical-align: middle;
    margin: 0 1.2rem 0 0;
  }
}

.price-icon {
  &:before {
    content: "";
    display: inline-block;
    background: url('#{$img-path}icons/ic_cost.png') no-repeat;
    width: 51px;
    height: 51px;
    vertical-align: middle;
    margin: 0 1.9rem 0 0;
  }
}



.bottom-border_dashed {
  border-bottom: 1px dashed $blue-text;
}

.v-center {
  align-items: center;
}

footer {
  padding-top: 60px;
  padding-bottom: 20px;

  @media (max-width: 992px) {
    padding-top: 0;
  }
}

.footer-bottom {
  color: #9bccff;
  border-top: 1px solid #0a5cb2;
  padding-top: 20px;

  @media (max-width: 992px) {
    border: none;
  }
}

.footer-bottom__link {
  color: #9bccff!important;
  text-decoration: underline;
}

.footer-nav {
  list-style-type: none;
  padding: 0;

  li {
    padding-bottom: 15px;
    a {
      font-size: 1.1rem;
      color: #e3f1ff;
      text-decoration: none!important;
    }

    &:first-child {
      padding-bottom: 20px;
      a {
        color: #c2e0ff;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }
}

.footer-nav_mobile {
  position: relative;
  display: block;
  color: $light-text!important;
  text-decoration: none!important;
  font-size: 1.2rem;
  padding: 15px 55px 15px 20px;
  border-bottom: 1px solid #6285ae;

  &[aria-expanded="false"]{
    .collapse-arrow-down {
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.footer-nav_mobile_sub {
  padding: 15px 55px 15px 20px;
  margin-bottom: 0;

  li {
    line-height: 1.8rem;
  }
}

.collapse-arrow-down {
  width: 9px;
  height: 4px;
  color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) rotateX(180deg);
  transform: translateY(-50%) rotateX(180deg);
}

.padding-top-sm {
  padding-top: 4rem;
}

.padding-top-md {
  padding-top: 5.5rem;
}

.padding-top-lg {
  padding-top: 6.2rem;
}

.padding-bottom {
  padding-bottom: 5.6rem;
}

.page-padding-bottom {
  padding-bottom: 4rem;
}

.page-padding-top {
  margin-top: 3.5rem;
}

.font-18 {
  font-size: 1.125rem;

  ol, ul {
    li {
      margin-bottom: 1rem;
    }

    margin-bottom: 2rem;
    padding-left: 20px;
  }
}

.blue-text {
  color: $blue-text;
}

.vbox-title {
  display: block!important;
}

.venobox {
  outline: none!important;
}

.bigger-text {
  font-size: 1.5rem;
  line-height: 1.9rem!important;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

.button {
  font-family: $main-font;
  border-radius: 30px;
  padding: 6px 22px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none!important;
  outline: none!important;
  border: none;
  white-space: nowrap;

  &.button_blue {
    background-color: $button-light-blue;
    color: $dark-blue-text;
    &:hover {
      background-color: $button-light-blue-hover;
    }
    &.active {
      color: $light-text!important;
      background-color: $button-light-blue-hover;
    }
  }

  &.button_orange {
    background-color: $button-orange;
    color: $light-text;
    &:hover {
      background-color: $button-orange-hover;
    }
  }

  &.button_form {
    background-color: $button-form;
    color: $light-text;
    font-weight: bold;
    font-size: 1.125rem;
    padding: 12px 70px;

    &:hover {
      background-color: $button-form-hover;
    }

    @media (max-width: 1200px) {
      padding: 12px 46px;
    }
  }

  &.button_turbo {
    background-color: $button-form;
    color: $light-text;
    font-weight: bold;
    font-size: 1.125rem;
    padding: 12px 47px;

    &:hover {
      background-color: $button-form-hover;
    }

    @media (max-width: 992px) {
      padding: 12px 25px;
    }
    @media (max-width: 768px) {
      display: block;
      margin-top: 30px;
    }
  }

  &.button_vacancy {
    background-color: $button-form;
    color: $light-text;
    font-weight: bold;
    padding: 10px 30px;
    font-size: 1rem;

    &:hover {
      background-color: $button-form-hover;
    }
  }

  &.button_large-text {
    font-size: 1.125rem;
    font-weight: bold;
    padding: 8px 27px;
  }

  &.button_small {
    @extend .button_blue;
    border-radius: 3px;
    text-align: center;
    padding: 6px 0;
    width: 35px;
    font-size: 0.9rem;
  }

  &.button_login {
    font-size: 1.125rem;
    font-weight: bold;
    padding: 8px 22px 8px 12px;
  }

  &.button_dark-blue {
    background-color: $blue-text;
    color: $light-text;

    &:hover {
      background-color: $dark-blue-text;
    }
  }

  &.button_large {
    font-size: 1.3rem;
    font-weight: bold;
    padding: 10px 31px;
  }
}

.top-header {
  border-bottom: 6px solid #d1ddea;

  .button {
    display: inline-block;
  }

  @media (max-width: 992px) {
    padding: 5px 0 6px 0;
  }
}

.top-header__city {
  display: inline-block;
  cursor: pointer;
}

.top-header__nav {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  li {
    padding: 17px 20px 19px 20px;

    @media (max-width: 1200px) {
      padding: 17px 10px 19px 10px;
    }

    a {
      text-decoration: none;
      color: $dark-text;
    }

    &.active {
      background-color: $button-light-blue-active;

      a {
        color: $light-text;
      }
    }
  }
}

.top-header__phone {
  font-weight: bold;
  font-size: 1.4rem;
  vertical-align: middle;
  color: $dark-text;
  text-decoration: none!important;
  transition: all 0.3s ease-in-out;
}


.langs {
  align-items: center;

  .button_small {
    margin-left: 5px;
  }
}

.langs__select {
  width: 97px;
  color: $blue-text;
  padding: 7px 5px;
  height: 34px!important;
  margin-left: 5px;
  border: 1px solid #9bbbe0;
  font-size: 0.9rem;
}

.navbar {
  padding: 20px 0;

  .navbar-nav {
    .nav-link {
      color: $blue-text;
      font-weight: bold;
      font-size: 1.125rem;
      padding-right: 1rem;
      padding-left: 1rem;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $dark-blue-text;
      }

      @media (max-width: 1200px) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  .navbar-brand {
    margin-right: 4.7rem;

    @media (max-width: 1200px) {
      margin-right: 0.7rem;
      width: 16.5%;
    }

    @media (max-width: 992px) {
      margin-left: 1rem;
      width: 32%;
    }
  }

  .langs__select {
    display: inline-block;
  }

  .langs .button {
    background: #fff;
    border: 1px solid #9bbbe0;
    &:hover {
      background-color: $button-light-blue-hover;
    }
    &.active {
      background-color: $button-light-blue-hover;
    }
  }

  @media (max-width: 576px) {
    .button.button_large-text {
      padding: 8px 12px;
    }
  }
  @media (max-width: 992px) {
    .button_login {
      padding: 5px 0 6px 7px;
      display: block;
      color: $blue-text;
      &:hover {
        color: $dark-blue-text;
      }
    }
  }
}

.navbar-toggler {
  cursor: pointer;
  border: none;
  outline: none!important;
  margin-right: 0!important;
}

.navbar-collapse.collapsing, .navbar-collapse.show {
  @media (max-width: 992px) {
    top: 90%;
    background-color: $button-light-blue;
    padding-top: 10px;
    margin-top: 0;
    z-index: 99;
  }
}

.navbar__buttons {
  .button_login {
    margin-left: 10px;
  }
}

.index-slider {
  margin-bottom: 41px;
}

.index-slider__item {
  height: 546px;
  outline: none;
  background-position: center;

  &.index-slider__item_local {
    background-image: url('#{$img-path}index/slider/slide1.jpg');
  }
  &.index-slider__item_translate {
    background-image: url('#{$img-path}index/slider/slide2.jpg');
  }
  &.index-slider__item_law {
    background-image: url('#{$img-path}index/slider/slide3.jpg');
  }
  &.index-slider__item_legacy {
    background-image: url('#{$img-path}index/slider/slide4.jpg');
  }
  &.index-slider__item_logistic {
    background-image: url('#{$img-path}index/slider/slide5.jpg');
  }
}

.index-slider__header {
  margin-top: 8rem;
  font-weight: bold;
  color: $light-text;
  font-size: 3.75rem;
  margin-bottom: 0.3rem;

  @media (max-width: 768px) {
    line-height: 4rem;
  }
}

.index-slider__desc {
  color: $light-text;
  font-size: 1.31rem;
  margin-bottom: 2.5rem;
}

.slider-dots {
  list-style-type: none;
  position: relative;

  li {
    display: inline-block;
    margin-right: 10px;

    button {
      cursor: pointer;
      border: none;
      color: transparent;
      border-radius: 20px;
      padding: 0;
      outline: none;
    }
  }
}

.slider-dots_index {
  margin-top: -65px;
  padding-left: 115px;

  li {
    button {
      background-color: #00478f;
      width: 18px;
      height: 18px;
    }

    &.slick-active {
      button {
        background-color: #f15922;
      }
    }
  }

  @media (max-width: 1200px) {
    padding-left: 15px;
  }
}

.slider-dots_feedback {
  margin-top: 20px;
  text-align: center;

  li {
    button {
      background-color: #909090;
      width: 14px;
      height: 14px;
    }

    &.slick-active {
      button {
        background-color: #2582c9;
      }
    }
  }
}

.index-service__header {
  font-size: 2rem;
  line-height: 4rem;
  color: $dark-text!important;
  text-decoration: none!important;
  transition: all 0.3s ease-in-out;
  display: block;
}

.index-service__border_dark {
  border-bottom: 6px solid #2977c6;
}
.index-service__border_mid {
  border-bottom: 6px solid #5faeff;
}
.index-service__border_light {
  border-bottom: 6px solid #9eceff;
}

.index-service__link {
  color: $blue-text;
  display: block;
  font-size: 1.125rem;
  line-height: 1.4rem;
  text-decoration: none!important;
  padding-top: 30px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $dark-blue-text;
  }
}

.index-service__last-link {
  color: #00264c;
  display: block;
  font-weight: bold;
  font-size: 0.9rem;
  padding-top: 30px;
  text-decoration: none!important;
  transition: all 0.3s ease-in-out;

  @media (max-width: 992px) {
    margin-bottom: 40px;
  }
}

.index-speakus {
  background-image: url('#{$img-path}index/bg_about_speakus.jpg');
  background-position: center;
  min-height: 430px;
}

.index-turbo {
  background-image: url('#{$img-path}index/bg_about_24turbo.jpg');
  background-position: center;
  min-height: 430px;

  @media (max-width: 576px) {
    background-position: right;
  }
}

.index-speakus__header {
  color: $light-text;
  font-size: 1.9rem;
  margin-left: 30px;

  @media (max-width: 576px) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-left: 0;
  }
}

.index-speakus__desc {
  color: $light-text;
  font-size: 1.13rem;
  margin-left: 30px;

  @media (max-width: 576px) {
    background-position: right;
    margin-left: 0;
  }
}

.index-speakus__border {
  border-bottom: 2px solid #4f8fd4;
  padding-bottom: 38px;
  margin-bottom: 38px;
  margin-left: 30px;

  @media (max-width: 576px) {
    background-position: right;
    margin-left: 0;
  }
}

.index-speakus__link {
  color: $light-text!important;
  text-decoration: none!important;
  font-weight: bold;
  font-size: 1.13rem;
  margin-left: 30px;

  @media (max-width: 576px) {
    background-position: right;
    margin-left: 0;
  }
}

.index-header {
  font-size: 2.5rem;
  margin-bottom: 2rem;

  &.index-header_dark {
    color: $dark-header-text;
  }

  &.index-header_light {
    color: $light-text;
  }
}

.index-time__desc {
  font-size: 1.125rem;
}

.index-time_img {
  background-image: url('#{$img-path}index/line_deadline.png');
  background-position: center;
  height: 92px;
  margin-top: 85px;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    height: 76px;
    background-size: cover;
  }
}

.index-time__title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0;

  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }
}

.index-feedback {
  background-image: url('#{$img-path}index/bg_reviews.jpg');
  background-position: center;
  min-height: 780px;
  margin-bottom: -16px;
}

.feedback-slider__wrapper {
  padding: 0 15px;
}

.feedback-slider__item {
  outline: none;
  min-height: 410px;
  display: flex;
  background-color: $light-text;
  padding: 50px 55px 50px 35px;
  text-decoration: none!important;

  @media (max-width: 1200px) {
    min-height: 435px;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    min-height: 390px;
    padding: 20px 20px 20px 20px;
  }
}

.feedback-slider__item_left {
  margin-right: 30px;

  img {
    max-width: 101px;
  }

  @media (max-width: 1200px) {
    margin-right: 15px;
  }

  @media (max-width: 576px) {
    margin: 0 auto 20px auto;
  }
}

.feedback-slider__text {
  font-size: 1.125rem;
  color: $dark-text;
  margin-bottom: 2rem;
}

.feedback-slider__name {
  font-size: 1.125rem;
  color: $blue-text;
}

.feedback-slider__nav {
  position: relative;
  display: block;

  @media (max-width: 576px) {
    display: none;
  }
}

.feedback-slider__nav div {
  width: 31px;
  height: 71px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  margin-top: 164px;
}

.feedback-slider__nav_next {
  background-image: url('#{$img-path}base/arrow_next.png');
  right: -30px;
}

.feedback-slider__nav_prev {
  background-image: url('#{$img-path}base/arrow_prev.png');
  left: -30px;
}

.social-icon__fb {
  margin-right: 15px;

  @media (max-width: 768px) {
    display: inline-block;
    margin-top: 10px;
  }
}