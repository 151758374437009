.modal {
  text-align: center;

  &:before {
    content: '';
    height: 100%;
    width: 1px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 992px) {
      height: 10%;
    }
  }

  .modal-body {
    padding: 30px 35px 20px 30px;
  }

  .modal-dialog {
    text-align: left;
    margin: 10px auto;
    display: inline-block;
    vertical-align: middle;

    @media (min-width: 992px) {
      min-width: 550px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  button.close {
    outline: none;
    text-align: right;
    margin: 5px 10px 0 10px;
    cursor: pointer;
  }
}

.modal-backdrop {
  background-color: #001e4f;

  &.show {
    opacity: 0.7;
  }
}

.modal-open {
  padding-right: 0!important;
}

.modal-bg {
  background-color: $modal-bg;
}

.modal-h1 {
  font-size: 1.9rem;
  color: $dark-header-text;
}

.modal-p {
  font-size: 1.125rem;
  margin-bottom: 2rem;
}

.modal-form {
  min-height: 300px;
}

.modal-close {
  background: url('#{$img-path}icons/ic_close.png') no-repeat;
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: 999;
  margin: 30px 15px;
  right: 0;
  cursor: pointer;
}


.choose-city {
  list-style-type: none;
  padding: 0 60px 0 2px;

  li {
    cursor: pointer;
    color: $blue-text;
    font-weight: bold;
    font-size: 1.15rem;
    border-bottom: 1px solid $gray-border;
    padding: 15px 0 10px 0;
  }
}