html, body {
  height: 100%;
  font-family: $main-font;
  font-size: 16px;
  color: $dark-text;

  @media (max-width: 992px) {
    font-size: 15px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    font-size: 13px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
  background-color: $footer-bg;
}

.alarm {
  display: none;
  padding-top: 10px;
  background-color: #fdbab9;
}

.alarm__text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.alarm__close {
  background-image: url('#{$img-path}base/alarm-close.png');
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin: 6px 20px;
}